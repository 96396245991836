<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row>
      <b-col class="mt-4">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: UserListPath.name }">
          user
        </router-link>
      </b-col>
    </b-row>
    <b-card header="Add User" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
      <b-form @submit="onSubmit" @reset="reset">
        <b-row>
          <b-col lg="4">
            <b-form-group label="Username">
              <b-form-input v-model="form.username" type="text" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group label="Email">
              <b-form-input v-model="form.email" type="email" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group label="User Role">
              <b-form-select
                id="currency"
                v-model="roleSelected"
                :options="roleOptions"
                @input="onSelectRole"
                required
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group label="Password">
              <b-form-input v-model="form.password" :state="!(form.password.length < 8)" min="8" type="password" required></b-form-input>
              <small class="text-danger" v-if="form.password.length < 8">minimum 8 character</small>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group label="Confirm Password">
              <b-form-input v-model="form.confirmPassword" :state="form.password == form.confirmPassword && form.confirmPassword != ''" type="password" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox
                  v-model="form.is_active"
                  :value="true"
                  :unchecked-value="false"
                >Active</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary">Submit</b-button>
        <b-button type="reset" variant="secondary" class="ml-2">Reset</b-button>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { UserListPath } from '../../../router/settings';
export default {
  data() {
    return {
      UserListPath,
      form: {
        username: null,
        email: null,
        password: '',
        confirmPassword: '',
        role_id: null,
        is_active: false,
      },
      warningMinPassword: false,
      roleSelected: null,
      roleOptions: [
        { text: 'select role', value: null },
        { text: 'Admin', value: 5 },
        { text: 'Marketing', value: 6 },
        { text: 'Finance', value: 7 },
        { text: 'Banned Account', value: 19 },
      ]
    }
  },
  created() {
  },
  watch: {
    message: function () {
      if (!this.message) return;
      this.messageAlert(this.isError ? 'error' : 'success', this.message, 4000);
      if(!this.isError) this.$router.push(UserListPath);
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.admin.isLoading,
      isError: (state) => state.admin.isError,
      message: (state) => state.admin.message,
    }),
  },
  methods: {
    ...mapActions('admin', ['createAdminVendor']),
    onSubmit(event) {
      event.preventDefault();
      if (this.form.confirmPassword != this.form.password) return;
      this.createAdminVendor(this.form);
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    onSelectRole(data) {
      this.form.role_id = data;
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },

}
</script>